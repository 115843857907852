<template>

  <div v-if="loginCheck">
    <el-menu
      default-active="222"
      mode="horizontal"
      
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      
      <el-menu-item index="2"><el-icon><User /></el-icon>&nbsp;用户名：{{username}}</el-menu-item>
    </el-menu>
  </div>

  <div v-if="!loginCheck">
    <el-menu
      mode="horizontal"

      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="2" @click="showLogin()"><el-icon><User /></el-icon>&nbsp;登录</el-menu-item>
    </el-menu>
  </div>

  <div class="base-app-class">

    <el-menu

      :default-openeds="openeds"
      id="el-menu-element"
      style="border-right: 0px;height: 100vh;"
      :default-active="$route.path"
      class="el-menu-demo"
      mode="vertical">

      <el-menu-item index="/analysis" @click="gotoAnalysis()"><el-icon><DataAnalysis /></el-icon>数据看板</el-menu-item>

      <el-menu-item index="/account" @click="gotoAccount()"><el-icon><User /></el-icon>用户管理</el-menu-item>

      <el-sub-menu index="1">
        <template #title><el-icon><Menu /></el-icon>发货回款模块</template>
        <el-menu-item index="/fahuohuikuan/fahuoshuju" @click="showFahuoshuju()"><el-icon><TopRight /></el-icon>发货数据</el-menu-item>
        <el-menu-item index="/fahuohuikuan/huikuanshuju" @click="showHuikuanshuju()"><el-icon><BottomLeft /></el-icon>回款数据</el-menu-item>
        <el-menu-item index="/fahuohuikuan/fahuohuikuanpipei"  @click="showFahuohuikuanpipei()"><el-icon><Switch /></el-icon>发货回款匹配</el-menu-item>
        <el-menu-item index="/fahuohuikuan/fahuoshuju_pipei_success" @click="showFahuoshujuPipeiSuccess()"><el-icon><Check /></el-icon>发货匹配成功</el-menu-item>
        <el-menu-item index="/fahuohuikuan/huikuanshuju_pipei_success"  @click="showHuikuanshujuPipeiSuccess()"><el-icon><Check /></el-icon>回款匹配成功</el-menu-item>
      </el-sub-menu>

      <el-sub-menu index="2">
        <template #title><el-icon><Menu /></el-icon>数据备案模块</template>
        <el-menu-item index="/shujubeian/gongsijiesuanzhengce" @click="showGongsijiesuanzhengce"><el-icon><TopRight /></el-icon>公司结算政策</el-menu-item>
        <el-menu-item index="/shujubeian/bumenyuliuzhengce" @click="showBumenyuliuzhengce"><el-icon><TopRight /></el-icon>部门预留政策</el-menu-item>

        <el-sub-menu index="3">
          <template #title><el-icon><Menu /></el-icon>结算政策</template> 
          <el-menu-item index="/shujubeian/jiesuan_tuiguanggongsi" @click="showJiesuanTuiguanggongsi"><el-icon><TopRight /></el-icon>推广公司</el-menu-item>
          <el-menu-item index="/shujubeian/jiesuan_xiaowei_one" @click="showJiesuanXiaoweiOne"><el-icon><TopRight /></el-icon>小微公司1</el-menu-item>
          <el-menu-item index="/shujubeian/jiesuan_xiaowei_two" @click="showJiesuanXiaoweiTwo"><el-icon><TopRight /></el-icon>小微公司2</el-menu-item>
        </el-sub-menu>
        
      </el-sub-menu>
      
      <el-sub-menu index="3">
        <template #title><el-icon><Menu /></el-icon>系统设置</template>
        <el-menu-item index="1-1" @click="showAgreement()"><el-icon><Warning /></el-icon>隐私协议</el-menu-item>
        <el-menu-item index="1-2" @click="clearRecord()"><el-icon><Delete /></el-icon>清除缓存</el-menu-item>
        <el-menu-item index="1-3"  @click="exitLogin()"><el-icon><Close /></el-icon>切换用户</el-menu-item>
      </el-sub-menu>
    
    </el-menu>
    
    <router-view class="router-class" id="main-element"></router-view>

    <!-- 网站底部版号 -->
    <div class='bottom-base-div'>
      <div class="bottom-text-div">
        Copyright © 2024-2024 艾莉亚科技 版权所有 
      </div>
      
      <div class="bottom-text-div" @click="gotoBeian()">
        沪ICP备 2023030527号-2
      </div>
    </div>

    <el-dialog v-model="dialogAgreementVisible" title="隐私协议">
      <div class="agreement-div" v-for="item in agreementData" :key="item">
        <h3>{{item}}</h3>
      </div>
    </el-dialog>

    <!-- 登录弹框 -->
    <el-dialog :fullscreen="true" 
      style="--el-dialog-padding-primary: 0px" 
      :close-on-press-escape="false" 
      :show-close="false" 
      v-model="dialogLoginVisible">

      <div class="login">
        <div class="mylogin">
          <h4>登录</h4>
          <el-form
            :rules="loginRules"
            ref="loginForm"
            label-width="0px">

            <el-form-item label="" style="margin-top: 10px; display: inline-flex;">
              <el-row>
                <el-col :span="2">
                  <el-icon><User /></el-icon>
                </el-col>
                <el-col :span="22">
                  <el-input
                    style="margin-left: 10px;"
                    class="inps"
                    placeholder="账号"
                    v-model="username"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item label="" style="display: inline-flex;">
              <el-row>
                <el-col :span="2">
                  <el-icon><Key /></el-icon>
                </el-col>
                <el-col :span="22">
                  <el-input
                    style="margin-left: 10px;"
                    class="inps"
                    type="password"
                    placeholder="密码"
                    v-model="pwd"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item style="margin-top: 55px;display: inline-flex;">
              <el-button type="primary" round class="submitBtn" @click="doLogin"
                >登录
              </el-button>
            </el-form-item>
            <el-form-item style="margin-top: -10px;display: inline-flex;">
              <div class="submitBtn">
                <el-text style="font-size: small">沪ICP备 2023030527号-2</el-text>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>

import { ElMessageBox, ElMessage } from 'element-plus'
import { ref } from 'vue'
import { h } from 'vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    
  },
  data () {
    return {
      dialogAgreementVisible: ref(false),
      dialogRegisterVisible: ref(false),
      dialogLoginVisible: ref(false),
      agreementData: [],
      username: '',
      pwd: '',
      copypwd: '',
      openeds: ['1'],
      loginCheck: false,

      loginRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    }
  },

  created() {
    this.createAgreementData();
  },

  mounted() {
    //检测登录状态
    this.checkLogin();
    if(!this.loginCheck) {
      this.dialogLoginVisible = true;
    }

    this.setMainWidth();

    // add key binding
    // window.addEventListener("keydown", (e) => {
    //   console.log(e.key);
    //   if (e.key === " " || e.key === "Enter") {
    //     this.doLogin();
    //   }
    // });
    
  },
  methods: {
    setMainWidth() {
      //设置宽度
      // 获取当前页面总宽度（视口宽度）
      const pageWidth = window.innerWidth;
      console.log('当前页面总宽度：', pageWidth);

      // 获取组件 A 的宽度
      const componentA = document.getElementById('el-menu-element');
      const componentWidth = componentA.offsetWidth;
      console.log('组件 A 的宽度：', componentWidth);

      // document.getElementById('main-element').style.width = '600px';
    },

    gotoBeian() {
      window.open("https://beian.miit.gov.cn", "_black")
    },

    showGongsijiesuanzhengce() {
      //公司结算政策
      this.$router.push({
        path: '/shujubeian/gongsijiesuanzhengce'
      })
    },

    showBumenyuliuzhengce() {
      //部门预留政策
      this.$router.push({
        path: '/shujubeian/bumenyuliuzhengce'
      })
    },

    showJiesuanTuiguanggongsi() {
      //结算政策-推广公司
      this.$router.push({
        path: '/shujubeian/jiesuan_tuiguanggongsi'
      })
    },

    showJiesuanXiaoweiOne() {
      //结算政策-小微公司1
      this.$router.push({
        path: '/shujubeian/jiesuan_xiaowei_one'
      })
    },

    showJiesuanXiaoweiTwo() {
      //结算政策-小微公司2
      this.$router.push({
        path: '/shujubeian/jiesuan_xiaowei_two'
      })
    },

    gotoAnalysis() {
      //数据看板页面
      this.$router.push({
        path: '/analysis'
      })
    },

    gotoAccount() {
      this.$router.push({
        path: '/account'
      })
    },

    doLogin() {
      let that = this;
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        ElMessage.info('请输入用户名、密码')
        return;
      }
      if(this.username != "admin") {
        ElMessage.info('登录失败')
        return;
      }
      const options = {
        method: 'GET',
        url: '/login/check',
        headers: {
          pwd: that.pwd
        }
       };
      axios(options)
      .then(function (response) {
        console.log(response)
        
        if(response.data.code != 200) {
          ElMessage.error(response.data.msg)
          return;
        }

        localStorage.setItem('username', that.username);
        localStorage.setItem('pwd', that.pwd);
        that.checkLogin();
        ElMessage.success("登录成功");
        that.dialogLoginVisible = false;

      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('数据处理失败')
      });
    },

    clearRecord() {
      this.username = localStorage.getItem('username');
      this.pwd = localStorage.getItem('pwd');
      localStorage.clear();
      localStorage.setItem('username', this.username);
      localStorage.setItem('pwd', this.pwd);
      this.checkLogin();
      ElMessage.success("清除成功");
    },

    exitLogin() {
      localStorage.clear();
      this.checkLogin();
    },

    checkNull(data) {
      if(data == undefined || data == null || data.length <= 0) {
        return true;
      }
      return false;
    },

    checkLogin() {
      this.username = localStorage.getItem("username")
      this.pwd = localStorage.getItem("pwd")
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        this.loginCheck = false
      } else {
        this.loginCheck = true
      }
    },

    showHuikuanshujuPipeiSuccess() {
      this.$router.push({
        path: '/fahuohuikuan/huikuanshuju_pipei_success'
      })
    },

    showFahuoshujuPipeiSuccess() {
      this.$router.push({
        path: '/fahuohuikuan/fahuoshuju_pipei_success'
      })
    },

    showFahuoshuju() {
      this.$router.push({
        path: '/fahuohuikuan/fahuoshuju'
      })
    },

    showFahuohuikuanpipei() {
      this.$router.push({
        path: '/fahuohuikuan/fahuohuikuanpipei'
      })
    },

    showHuikuanshuju() {
      this.$router.push({
        path: '/fahuohuikuan/huikuanshuju'
      })
    },

    showAgreement() {
      this.dialogAgreementVisible = true;
    },

    showRegister() {
      this.dialogRegisterVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    showLogin() {
      this.dialogLoginVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    registerCancel() {
      this.dialogRegisterVisible = false
      this.username = ''
      this.pwd = ''
    },

    loginCancel() {
      this.dialogLoginVisible = false
    },

    createAgreementData() {
      var baseTitle = "艾莉亚科技";

      this.recordArr = new Array();
      var info1 = "如有疑问请联系客服进行协商处理。";
      this.recordArr.push(info1);
      
      var info2 = "联系客服：860993707@qq.com";
      this.recordArr.push(info2);

      this.agreementData = this.recordArr;
    }
  }
}
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

.customer_el_form_item_label .el-form-item__label{
  color: transparent;
}

.base-text {
  color: #01AAED
}

.agreement-div {
  text-align: left;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .black-menu-itme {
  flex: auto;
} */

.el-message-box-image-class {
  width: 160px;
  height: 160px;
  margin-top: 20px;
  will-change: transform;
	overflow:hidden;
  border-radius: 10px 10px 10px 10px;
  /* box-shadow: 8px 8px 6px #DDDDDD; */
}

.router-class {
  margin-bottom: 66px;
  flex: 1;
}

.base-app-title-class {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.base-app-class {
  display: flex;
  flex-direction: row;
}

.bottom-text-div {
  color: #a6a6a6;
  font-size: 10px;
}

.bottom-base-div {
  text-align: center;
  width: 60%; 
  position: fixed;
  bottom: 0px;
  margin-left: 20%;
  margin-right: 20%;
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
    width: 100vw;
    padding: 0;
    margin: 0;
    height: 100vh;
    font-size: 16px;
    background-position: left top;
    background-color: #242645;
    color: #fff;
    font-family: "Source Sans Pro";
    position: relative;
  }
 
  .mylogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    height: 280px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 50px 40px 40px 40px;
    box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
    opacity: 1;
    background: linear-gradient(
      230deg,
      rgba(53, 57, 74, 0) 0%,
      rgb(0, 0, 0) 100%
    );
  }
 
  .inps input {
    border: none;
    background-color: transparent;
    font-size: 12px;
  }
 
  .submitBtn {
    background-color: transparent;
    color: #39f;
    width: 200px;
  }

</style>